import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

function Picture({ data, rounded }: { data: any; rounded?: any }) {
  return (
    <picture className="component-Picture animated faster fade-in">
      <source srcSet={data?.mobile} type="image/webp" media="(max-width: 768px)" />
      <source srcSet={data?.webp} type="image/webp" />
      <img
        loading={data?.loading ?? (data?.ldc ? 'eager' : 'lazy')}
        fetch-priority={data?.ldc ? 'high' : 'auto'}
        src={data?.original}
        alt={data?.alt}
        width={data?.width}
        height={data?.height}
        style={{
          maxHeight: data?.maxHeight,
          maxWidth: data?.maxWidth,
          borderRadius: rounded ? '50%' : '',
        }}
      />
      {data?.caption && <div className="caption">{data?.caption}</div>}
    </picture>
  );
}

Picture.propTypes = {
  data: PropTypes.any,
};

export default Picture;
