import React, { memo } from 'react';
import icFaceBook from '../../images/icons/ic-facebook.svg';
import icInsta from '../../images/icons/ic-insta.svg';
import icLinkedIn from '../../images/icons/ic-linkedin.svg';
import {
  FOOTER_ID,
  PHONE_CONTACT,
  CUSTOMER_SERVICE_HOURS,
  SPECIAL_CUSTOMER_SERVICE_HOURS,
  //STORE_HOURS,
  //SPECIAL_STORE_HOURS,
  //SPECIAL_KIDS_STORE_HOURS,
  //KIDS_STORE_HOURS,
  //PREFIX_URL_DEFAULT,
  PREFIX_URL_KIDS,
} from '../../config';
import { useSelector } from 'react-redux';
import SSLCOMMERZALL from '../../images/SSLCOMMERZALL.webp';
import SSLCOMMERZLOGO from '../../images/SSLCOMMERZLOGO.webp';
import FooterLogo from '../../images/block-video/bg-video-block.webp';
import KidsFooterLogo from '../../images/sun-H-white-min.png';
import { prefix } from '../../utils/customHooks/usePrefixSegment';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { isMobile } from 'react-device-detect';
import { Typography } from '@mui/material';

function Footer() {
  const globalConfigReducer = useSelector((state: any) => state?.globalConfigReducer);
  const pathname = window.location.pathname;
  return pathname !== '/store/register' ? (
    <div className="footer-container" id={FOOTER_ID}>
      <footer className="blk-footer" id="blk-footer">
        <div className="content container">
          <div>
            <div className="item-box">
              {isMobile ? (
                <Accordion
                  sx={{
                    backgroundColor: 'transparent',
                    color: 'inherit',
                    boxShadow: 'none',
                    padding: '0px',
                  }}
                  square={true}
                  disableGutters={true}
                >
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreIcon
                        style={{
                          color: 'inherit',
                          fontFamily: 'inherit',
                        }}
                      />
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                      padding: '0px',
                      margin: 'auto',
                    }}
                  >
                    <Typography variant="h3" style={{ marginBottom: '0px', minHeight: '0px' }}>
                      CONTACT
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <>
                      <ul>
                        <li>
                          <a>
                            <span
                              className="icon-format material-icons"
                              style={{ top: -4, left: -28 }}
                            >
                              call
                            </span>
                            <a href={`tel:${PHONE_CONTACT}`}>{PHONE_CONTACT}</a>
                          </a>
                        </li>

                        {CUSTOMER_SERVICE_HOURS ? (
                          <li>
                            <a>
                              <span
                                className="icon-format material-icons"
                                style={{ top: -4, left: -28 }}
                              >
                                schedule
                              </span>
                              <a>
                                {SPECIAL_CUSTOMER_SERVICE_HOURS
                                  ? SPECIAL_CUSTOMER_SERVICE_HOURS
                                  : CUSTOMER_SERVICE_HOURS}
                              </a>
                            </a>
                          </li>
                        ) : null}

                        <li>
                          <a href="mailto:support@sundora.com.bd">
                            <span
                              className="icon-format material-icons"
                              style={{ top: -4, left: -28 }}
                            >
                              email
                            </span>
                            support@sundora.com.bd
                          </a>
                        </li>
                      </ul>
                      <ul className="social-group" style={{ marginLeft: -32 }}>
                        <li>
                          <a
                            target="__blank"
                            href="https://bd.linkedin.com/company/sundora?trk=public_profile_topcard-current-company"
                          >
                            <img
                              className="linkedin"
                              src={icLinkedIn}
                              width={35}
                              height={22}
                              alt="ic-linkedin"
                              loading="lazy"
                              fetch-priority="low"
                            />
                          </a>
                        </li>
                        <li>
                          <a target="__blank" href="https://www.facebook.com/sundorabeauty">
                            <img
                              src={icFaceBook}
                              width={14}
                              height={22.39}
                              alt="ic-facebook"
                              loading="lazy"
                              fetch-priority="low"
                            />
                          </a>
                        </li>
                        <li>
                          <a target="__blank" href="https://www.instagram.com/sundorabeauty">
                            <img
                              src={icInsta}
                              width={21}
                              height={24}
                              alt="ic-instagram"
                              loading="lazy"
                              fetch-priority="low"
                            />
                          </a>
                        </li>
                      </ul>
                      <ul>
                        <li>
                          <a href="#">
                            <span
                              className="icon-format material-icons"
                              style={{ top: -4, left: -28 }}
                            >
                              copyright
                            </span>
                            FCBT Ventures Pvt Ltd
                          </a>
                        </li>
                      </ul>
                    </>
                  </AccordionDetails>
                </Accordion>
              ) : (
                <>
                  <h3>contact</h3>
                  <ul>
                    <li>
                      <a>
                        <span
                          className="icon-format material-icons"
                          style={{ top: -4, left: -28 }}
                        >
                          call
                        </span>
                        <a href={`tel:${PHONE_CONTACT}`}>{PHONE_CONTACT}</a>
                      </a>
                    </li>

                    {CUSTOMER_SERVICE_HOURS ? (
                      <li>
                        <a>
                          <span
                            className="icon-format material-icons"
                            style={{ top: -4, left: -28 }}
                          >
                            schedule
                          </span>
                          <a>
                            {SPECIAL_CUSTOMER_SERVICE_HOURS
                              ? SPECIAL_CUSTOMER_SERVICE_HOURS
                              : CUSTOMER_SERVICE_HOURS}
                          </a>
                        </a>
                      </li>
                    ) : null}

                    <li>
                      <a href="mailto:support@sundora.com.bd">
                        <span
                          className="icon-format material-icons"
                          style={{ top: -4, left: -28 }}
                        >
                          email
                        </span>
                        support@sundora.com.bd
                      </a>
                    </li>
                  </ul>
                  <ul className="social-group" style={{ marginLeft: -32 }}>
                    <li>
                      <a
                        target="__blank"
                        href="https://bd.linkedin.com/company/sundora?trk=public_profile_topcard-current-company"
                      >
                        <img
                          className="linkedin"
                          src={icLinkedIn}
                          width={35}
                          height={22}
                          alt="ic-linkedin"
                          loading="lazy"
                          fetch-priority="low"
                        />
                      </a>
                    </li>
                    <li>
                      <a target="__blank" href="https://www.facebook.com/sundorabeauty">
                        <img
                          src={icFaceBook}
                          width={14}
                          height={22.39}
                          alt="ic-facebook"
                          loading="lazy"
                          fetch-priority="low"
                        />
                      </a>
                    </li>
                    <li>
                      <a target="__blank" href="https://www.instagram.com/sundorabeauty">
                        <img
                          src={icInsta}
                          width={21}
                          height={24}
                          alt="ic-instagram"
                          loading="lazy"
                          fetch-priority="low"
                        />
                      </a>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <a href="#">
                        <span
                          className="icon-format material-icons"
                          style={{ top: -4, left: -28 }}
                        >
                          copyright
                        </span>
                        FCBT Ventures Pvt Ltd
                      </a>
                    </li>
                  </ul>
                </>
              )}
            </div>
            {/*
            <div className="item-box">
              
              <h3>visit</h3>
              {prefix === PREFIX_URL_KIDS ? (
                <>
                  <ul>
                    <li>
                      <span>
                        {' '}
                        <a
                          href={`https://www.google.com/maps/search/${encodeURI(
                            'FLOOR, H, VENTURA AGNIBEENA, GR, 38 Rd 12, Dhaka 1213',
                          )}/`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span className="icon-format material-icons" style={{ left: -28 }}>
                            location_on
                          </span>
                          <p className="location">
                            Banani, Road 12, House 38, Ventura Agneebina, 1st floor, Dhaka,
                            Bangladesh
                          </p>
                        </a>
                      </span>
                    </li>
                    {STORE_HOURS ? (
                      <li>
                        <span>
                          {' '}
                          <a>
                            <span className="icon-format material-icons" style={{ left: -28 }}>
                              store
                            </span>
                            {SPECIAL_KIDS_STORE_HOURS
                              ? SPECIAL_KIDS_STORE_HOURS
                              : KIDS_STORE_HOURS}
                          </a>
                        </span>
                      </li>
                    ) : null}
                  </ul>
                </>
              ) : (
                <>
                  <ul>
                    <li>
                      <span>
                        <a
                          href={`https://www.google.com/maps/search/${encodeURI(
                            'Green City Square, Gr. Floor - Semi-basement, 750, Satmasjid Road, Dhanmondi',
                          )}/`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span className="icon-format material-icons" style={{ left: -28 }}>
                            location_on
                          </span>
                          <p className="location">
                            Green City Square, Gr. Floor - Semi-basement, 750, Satmasjid Road,
                            Dhanmondi
                          </p>
                        </a>
                      </span>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <span>
                        <a
                          href={`https://www.google.com/maps/search/${encodeURI(
                            'Delvista, Banani Rd# 12, FL# 3, H# 44, Banani',
                          )}/`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span className="icon-format material-icons" style={{ left: -28 }}>
                            location_on
                          </span>
                          <p className="location">
                            DELVISTA, FL# 3, H# 44,RD# 12, BANANI 1213, DHAKA
                          </p>
                        </a>
                      </span>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <span>
                        {' '}
                        <a
                          href={`https://www.google.com/maps/search/${encodeURI(
                            'Ventura Agnibeena, Gr. Floor, H#38, Rd#12, Banani',
                          )}/`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span className="icon-format material-icons" style={{ left: -28 }}>
                            location_on
                          </span>
                          <p className="location">
                            Ventura Agnibeena, Gr. Floor, H#38, Rd#12, Banani
                          </p>
                        </a>
                      </span>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <span>
                        {' '}
                        <a
                          href={`https://www.google.com/maps/search/${encodeURI(
                            'Casablanca, 114 Gulshan Ave, Gr. Floor, Gulshan',
                          )}/`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span className="icon-format material-icons" style={{ left: -28 }}>
                            location_on
                          </span>
                          <p className="location">
                            Casablanca, 114 Gulshan Ave, Gr. Floor, Gulshan
                          </p>
                        </a>
                      </span>
                    </li>
                    {STORE_HOURS ? (
                      <li>
                        <span>
                          {' '}
                          <a>
                            <span className="icon-format material-icons" style={{ left: -28 }}>
                              store
                            </span>
                            {SPECIAL_STORE_HOURS ? SPECIAL_STORE_HOURS : STORE_HOURS}
                          </a>
                        </span>
                      </li>
                    ) : null}
                  </ul>
                </>
              )}
              <h3>BLOGS</h3>
            </div>*/}

            {/* sort & map links from array of objects */}
            {globalConfigReducer?.footer &&
              globalConfigReducer?.footer?.map((policy: any) => {
                return (
                  <>
                    {isMobile ? (
                      <div key={policy.id} className="item-box">
                        <Accordion
                          sx={{
                            backgroundColor: 'transparent',
                            color: 'inherit',
                            boxShadow: 'none',
                            padding: '0px',
                            marginTop: '-12px',
                          }}
                          square={true}
                          disableGutters={true}
                        >
                          <AccordionSummary
                            expandIcon={
                              <ExpandMoreIcon
                                style={{
                                  color: 'inherit',
                                  fontFamily: 'inherit',
                                }}
                              />
                            }
                            aria-controls={`panel${policy.id}-content`}
                            id={`panel${policy.id}-header`}
                            sx={{
                              padding: '0px',
                              margin: 'auto',
                            }}
                          >
                            <Typography
                              variant="h3"
                              style={{ marginBottom: '0px', minHeight: '0px' }}
                            >
                              {policy.title}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <ul>
                              {policy.children &&
                                policy.children
                                  .sort((a: any, b: any) => a.id - b.id)
                                  .map((item: any) => {
                                    return (
                                      <li key={item.id}>
                                        <a
                                          href={item.relative_url}
                                          className="py-1"
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          <span
                                            className="icon-format material-icons"
                                            style={{ top: 0, left: -28 }}
                                          >
                                            {item?.menu_icon}
                                          </span>
                                          <span>{item.title} </span>
                                        </a>
                                      </li>
                                    );
                                  })}
                            </ul>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    ) : (
                      <div key={policy.id} className="item-box">
                        <h3>{policy.title}</h3>
                        <ul>
                          {policy.children &&
                            policy.children
                              .sort((a: any, b: any) => a.id - b.id)
                              .map((item: any) => {
                                return (
                                  <li key={item.id}>
                                    <a
                                      href={item.relative_url}
                                      className="py-1"
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <span
                                        className="icon-format material-icons"
                                        style={{ top: 0, left: -28 }}
                                      >
                                        {item?.menu_icon}
                                      </span>
                                      <span>{item.title} </span>
                                    </a>
                                  </li>
                                );
                              })}
                        </ul>
                      </div>
                    )}
                  </>
                );
              })}

            <div className="item-box">
              {isMobile ? (
                <>
                  <Accordion
                    sx={{
                      backgroundColor: 'transparent',
                      color: 'inherit',
                      boxShadow: 'none',
                      padding: '0px',
                      marginTop: '-12px',
                    }}
                    square={true}
                    disableGutters={true}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon style={{ color: 'inherit' }} />}
                      aria-controls="panel1c-content"
                      id="panel1c-header"
                      sx={{
                        padding: '0px',
                        margin: '0px',
                      }}
                    >
                      <Typography
                        variant="h3"
                        style={{ marginBottom: '0px', minHeight: '0px' }}
                      >
                        PAYMENTS ACCEPTED
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <ul>
                        <li>CASH ON DELIVERY</li>
                        <li>BKASH</li>
                        <li>
                          <Accordion
                            sx={{
                              backgroundColor: 'transparent',
                              color: 'inherit',
                              boxShadow: 'none',
                              padding: '0px',
                              marginTop: '-12px',
                            }}
                            square={true}
                            disableGutters={true}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon style={{ color: 'inherit' }} />}
                              aria-controls="panel2a-content"
                              id="panel2a-header"
                              sx={{
                                padding: '0px',
                                margin: '0px',
                              }}
                            >
                              ONLINE PAYMENTS
                            </AccordionSummary>
                            <AccordionDetails
                              sx={{
                                padding: '0px',
                              }}
                            >
                              <a
                                href="https://sslcommerz.com/"
                                target="_blank"
                                title="SSLCommerz"
                                rel="noreferrer"
                                id="ssl-image"
                              >
                                <img
                                  style={{ maxWidth: '200px', marginLeft: '-5px' }}
                                  alt="SSL Commerz"
                                  src={SSLCOMMERZLOGO}
                                  loading="lazy"
                                  fetch-priority="low"
                                />
                              </a>
                              <img
                                alt="Card Payment"
                                className="ssl_image"
                                src={SSLCOMMERZALL}
                                loading="lazy"
                                fetch-priority="low"
                              />
                            </AccordionDetails>
                          </Accordion>
                        </li>
                      </ul>
                    </AccordionDetails>
                  </Accordion>
                </>
              ) : (
                <>
                  <h3>PAYMENTS ACCEPTED</h3>
                  <ul>
                    <li>CASH ON DELIVERY</li>
                    <li>BKASH</li>
                    <li>
                      <Accordion
                        sx={{
                          backgroundColor: 'transparent',
                          color: 'inherit',
                          boxShadow: 'none',
                          padding: '0px',
                          marginTop: '-12px',
                        }}
                        square={true}
                        disableGutters={true}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon style={{ color: 'inherit' }} />}
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                          sx={{
                            padding: '0px',
                            margin: '0px',
                          }}
                        >
                          ONLINE PAYMENTS
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{
                            padding: '0px',
                          }}
                        >
                          <a
                            href="https://sslcommerz.com/"
                            target="_blank"
                            title="SSLCommerz"
                            rel="noreferrer"
                            id="ssl-image"
                          >
                            <img
                              style={{ maxWidth: '200px', marginLeft: '-5px' }}
                              alt="SSL Commerz"
                              src={SSLCOMMERZLOGO}
                              loading="lazy"
                              fetch-priority="low"
                            />
                          </a>
                          <img
                            alt="Card Payment"
                            className="ssl_image"
                            src={SSLCOMMERZALL}
                            loading="lazy"
                            fetch-priority="low"
                          />
                        </AccordionDetails>
                      </Accordion>
                    </li>
                  </ul>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="footer-logo-container">
          <img
            className="footer-logo"
            src={prefix == PREFIX_URL_KIDS ? KidsFooterLogo : FooterLogo}
            alt="bg-video-block"
            width={350}
            height={179}
            loading="lazy"
            fetch-priority="low"
          />
        </div>
      </footer>
    </div>
  ) : (
    <></>
  );
}
export default memo(Footer);
