import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router';
import { prefix } from '../../utils/customHooks/usePrefixSegment';
import { LOCALSTORAGE_KEY_USER_LOCATION } from '../../config';

const CommonHelmet = () => {
  const location = useLocation();
  const [ogUrl, setOgUrl] = useState(window.location.href);

  useEffect(() => {
    setOgUrl(window.location.href);
  }, [location]);

  useEffect(() => {
    axios.defaults.headers.common['My-Department'] = prefix;
    axios.defaults.headers.common['My-Location'] =
      localStorage.getItem(LOCALSTORAGE_KEY_USER_LOCATION) || 'None';
  }, [location]);

  return (
    <Helmet>
      <meta property="og:url" content={ogUrl} />
    </Helmet>
  );
};

export default CommonHelmet;
