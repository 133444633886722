import React from 'react';
import './style.scss';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  PREFIX_URL_DEFAULT,
  //PREFIX_URL_HOME,
  PREFIX_URL_KIDS,
  //PREFIX_URL_SPORTS,
} from '../../config';
import { prefix } from '../../utils/customHooks/usePrefixSegment';
import SundoraKidsLogo from '../../images/kids-logo-1.png';
import SundoraLogo from '../../images/sundora-beauty_Gold.png';
import ProductBag from '../ProductBag';
import BagIcon from '../BagIcon';
import SearchBox from '../Banner/SearchBox';
const NavigationMobileV2 = ({ data }: any) => {
  const globalConfigReducer = useSelector((state: any) => state.globalConfigReducer);
  console.log(data);
  return (
    <div className="component-NavigationMobileV2">
      <div className="top">
        <div className="logo">
          <Link to="/">
            <img
              style={{ paddingTop: 5 }}
              src={
                globalConfigReducer?.logo
                  ? globalConfigReducer?.logo?.original
                  : prefix === PREFIX_URL_KIDS
                  ? SundoraKidsLogo
                  : SundoraLogo
              }
              width={100}
              height={62}
              alt="sundora-logo"
            />
          </Link>
        </div>
        <div className="search">
          <SearchBox />
        </div>
        <div className="cart">
          <BagIcon />
          <ProductBag />
        </div>
      </div>
      <div className="bot">
        <ul>
          <li className={prefix == PREFIX_URL_DEFAULT ? 'active' : ''}>
            <a href={`/${PREFIX_URL_DEFAULT}`}>BEAUTY</a>
          </li>
          <li className={prefix == PREFIX_URL_KIDS ? 'active' : 'kids-menu-item'}>
            <a href={`/${PREFIX_URL_KIDS}`}>KIDS</a>
          </li>
          {/*<li className={prefix == PREFIX_URL_SPORTS ? 'active' : ''}>
            <a href={`/${PREFIX_URL_SPORTS}`}>SPORTS</a>
          </li>
          <li className={prefix == PREFIX_URL_HOME ? 'active' : ''}>
            <a href={`/${PREFIX_URL_HOME}`}>HOME</a>
            </li>*/}
        </ul>
      </div>
    </div>
  );
};

export default NavigationMobileV2;
