import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import store from './redux/store';
import reportWebVitals from './reportWebVitals';
import { HelmetProvider } from 'react-helmet-async';
import * as Sentry from '@sentry/react';
import { LOCALSTORAGE_KEY_USER_LOCATION } from './config';
import { requestUserLocation } from './redux/Helpers';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    secondary: {
      main: '#282828',
    },
  },
  typography: {
    fontFamily: ['Niveau Grotesk', 'Roboto', 'sans-serif'].join(','),
  },
});

const dsn: any = process?.env?.REACT_APP_SENTRY_DSN;
if (dsn && location.hostname != 'localhost' && location.hostname != '127.0.0.1') {
  Sentry.init({
    dsn: dsn,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [new Sentry.Replay()],
  });
}

const renderApp = () => {
  ReactDOM.render(
    <HelmetProvider>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </Provider>
    </HelmetProvider>,
    document.getElementById('root'),
  );
};

const userLocation: any = localStorage.getItem(LOCALSTORAGE_KEY_USER_LOCATION);
if (!userLocation) {
  const res: any = requestUserLocation();
  res
    .then(() => {
      renderApp();
    })
    .catch(() => {
      renderApp();
    });
} else {
  renderApp();
}

reportWebVitals();
