import React, { memo, useRef, useState } from 'react';
import ProductSlide from '../ProductSlide';
import { map } from 'lodash';
import { RootStateOrAny, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import PromotePageForMenu from './PromotePageForMenu';
import { LOCALSTORAGE_KEY_USER_LOCATION } from '../../config';

function SubNavigation({
  isOpen = null,
  title = '',
  dataChild = [],
  promote = [],
  closeSubNav = null,
}: {
  isOpen: any;
  title: any;
  dataChild: any;
  promote: any;
  closeSubNav: any;
}) {
  const history = useHistory();
  const location = useLocation();
  const childRef = useRef<any>(null);
  const [childPage, setChildPage] = useState<any>([]);
  const [positionChild, setPositionChild] = useState<any>(10);
  const [currentParent, setCurrentParent] = useState<any>(null);

  const userReducer = useSelector((state: RootStateOrAny) => state?.userReducer);

  const onHoverParent = (dataChild: any, index: any) => {
    if (dataChild == childPage) {
      setChildPage([]);
      setCurrentParent(null);
    } else {
      setChildPage(dataChild);
      setCurrentParent(dataChild.id);
    }

    let offsetTop = 0;
    if (index != 1) {
      if (dataChild?.children.length <= index * 2 - 1) {
        offsetTop = (index - 1) * 32;
      }
    } else {
      offsetTop = 0;
    }
    setPositionChild(() => offsetTop);
  };

  const onMouseLeave = () => {
    setChildPage([]);
    setCurrentParent(null);
  };

  const navigateToFilter = (item: any) => {
    const a = item?.external_url
      ? item?.external_url
      : item?.relative_url
      ? item?.relative_url
      : '/';
    if (location.pathname !== a) {
      history.push(a);
    }
  };

  return (
    <ul className={`blk-sub-nav ${isOpen == true ? 'open-menu' : ''}`}>
      <li>
        <div className="container">
          <div className="row">
            <div className="col-4">
              <h2>{title}</h2>
              <div
                className="wrapper-sub-menu"
                onMouseLeave={onMouseLeave}
                style={{
                  overflow: 'auto',
                }}
              >
                <ul>
                  {dataChild.map((item: any, index: any) => {
                    if (item?.visible_only_to_logged_in_users) {
                      if (
                        !userReducer?.user &&
                        localStorage.getItem(LOCALSTORAGE_KEY_USER_LOCATION) != 'BD'
                      ) {
                        return;
                      }
                    }

                    return (
                      <li
                        key={index}
                        ref={childRef}
                        onMouseEnter={() => {
                          onHoverParent(item, index + 1);
                        }}
                        style={{ color: item?.menu_color }}
                      >
                        {item.disable_url ? (
                          <a className={`${currentParent == item.id ? 'activeParent' : ''}`}>
                            <span>
                              {item?.menu_link_icon && (
                                <img
                                  className="menu-link-icon"
                                  src={item?.menu_link_icon}
                                  alt={item.title}
                                />
                              )}
                              {item.title}{' '}
                            </span>
                            {item?.children.length > 0 || item.type == 'BrandPage' ? (
                              <i className="gg-chevron-right"></i>
                            ) : (
                              ''
                            )}
                          </a>
                        ) : (
                          <a
                            onClick={() => navigateToFilter(item)}
                            className={`${currentParent == item.id ? 'activeParent' : ''}`}
                          >
                            <span>
                              {item?.menu_link_icon && (
                                <img
                                  className="menu-link-icon"
                                  src={item?.menu_link_icon}
                                  alt={item.title}
                                />
                              )}
                              {item.title}{' '}
                            </span>
                            {item?.children.length > 0 || item.type == 'BrandPage' ? (
                              <i className="gg-chevron-right"></i>
                            ) : (
                              ''
                            )}
                          </a>
                        )}
                      </li>
                    );
                  })}
                </ul>
                <ul className="last-sub-flex">
                  <div
                    style={{
                      marginTop: positionChild,
                      transform:
                        positionChild > 0 && childPage.children
                          ? `translateY(-${(childPage?.children.length * 32) / 2 - 16}px)`
                          : 'unset',
                    }}
                  >
                    {map(childPage?.children, (itemchild, index) => {
                      if (itemchild?.visible_only_to_logged_in_users) {
                        if (!userReducer?.user) {
                          return;
                        }
                      }

                      return itemchild.type == 'BrandPage' ? (
                        <li key={index} style={{ color: itemchild?.menu_color }}>
                          <a onClick={() => navigateToFilter(itemchild)}>
                            {itemchild?.menu_link_icon && (
                              <img
                                className="menu-link-icon"
                                src={itemchild?.menu_link_icon}
                                alt={itemchild.title}
                              />
                            )}
                            {itemchild?.title}
                          </a>
                        </li>
                      ) : itemchild.type == 'FilteredPage' ? (
                        <li key={index} style={{ color: itemchild?.menu_color }}>
                          <a onClick={() => navigateToFilter(itemchild)}>
                            {itemchild?.menu_link_icon && (
                              <img
                                className="menu-link-icon"
                                src={itemchild?.menu_link_icon}
                                alt={itemchild.title}
                              />
                            )}
                            {itemchild?.title}
                          </a>
                        </li>
                      ) : (
                        <li key={index} style={{ color: itemchild?.menu_color }}>
                          <a onClick={() => navigateToFilter(itemchild)}>
                            <span>
                              {itemchild?.menu_link_icon && (
                                <img
                                  className="menu-link-icon"
                                  src={itemchild?.menu_link_icon}
                                  alt={itemchild.title}
                                />
                              )}
                              {itemchild.title}
                            </span>
                          </a>
                        </li>
                      );
                    })}
                  </div>
                </ul>
              </div>
            </div>
            <div className="col-8">
              <div className="row promote">
                {promote?.map((e: any, i: any) => {
                  switch (e?.type) {
                    case 'product':
                      return (
                        <div className="col" key={i}>
                          <h3 className="recommend-text">{e?.value?.headline}</h3>
                          <ProductSlide
                            data={e?.value?.products}
                            col={2}
                            closeSubNav={closeSubNav}
                          />
                        </div>
                      );
                    case 'promote_page_for_menu':
                      return (
                        <div className="col" key={i}>
                          <PromotePageForMenu data={e?.value} />
                        </div>
                      );
                    default:
                      return <div className="col" key={i}></div>;
                  }
                })}
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  );
}

export default memo(SubNavigation);
