import React from 'react';
import './styles.scss';
import { Helmet } from 'react-helmet-async';
import image from '../../assets/images/site_under_maintenance_4.png';
// import EID from './EID';

const SiteUnderMaintenance = () => {
  // return <EID />;
  return (
    <div className="component-SiteUnderMaintenance">
      <Helmet>
        <title>Site Maintenance</title>
      </Helmet>
      <h1 className="SiteUnderMaintenance-big-title">Website under maintenance</h1>
      <h1 className="SiteUnderMaintenance-title">We’ll be back soon!</h1>
      <div className="SiteUnderMaintenance-image">
        <img src={image} alt="" />
      </div>
      <div className="SiteUnderMaintenance-description">
        <p>
          Sorry for the inconvenience. We’re performing some maintenance at the moment. If you
          need to you can always email us at <a>support@sundora.com.bd</a> or call us at
          <a> +880 1966 444455</a> , otherwise <br /> we’ll be back up shortly!
        </p>
        <p>— The Sundora Team</p>
      </div>
    </div>
  );
};

export default SiteUnderMaintenance;
