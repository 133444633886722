import React, { useEffect, useRef, useState } from 'react';
import { SHOPIFY_DISCOUNT } from '../../config';
import { fetchClient, toastrError, toastrSuccess } from '../../redux/Helpers';
import { fetchCart, updateCart } from '../../redux/checkoutV2/utils';
import Picture from '../Picture';
import { DiscountEnum } from '.';

interface ImageUrl {
  alt: string;
  height: number;
  mobile: string;
  original: string;
  webp: string;
  width: number;
  maxWidth: string;
  maxHeight: string;
  caption: string;
}

interface Image {
  id: number;
  position: number | null;
  url: ImageUrl;
}
interface Product {
  id: number;
  name: string;
  title: string;
  image: Image;
  vendor: string;
  variant_id: number;
  variant_title: string;
  quantity: number;
}

interface DiscountedProducts {
  discountCode: string;
  products: Product[];
  quantityReceived: number;
  discountTitle: string;
  discountAmount: string;
  discountDescription: string;
}

const SelectFreeProduct = (props: any) => {
  const order = props?.order;
  const [discountedProducts, setDiscountedProducts] = useState<DiscountedProducts[]>([]);
  const [selectedProductsByGroup, setSelectedProductsByGroup] = useState<{
    [discountCode: string]: Product[];
  }>({});
  const draftOrderId = localStorage.getItem('id_checkout');
  const [error, setError] = useState<string | null>(null);
  const [, setItemsQuantity] = useState<any>([]);
  const ref: any = useRef();
  useEffect(() => {
    const fetchDiscounts = async () => {
      try {
        const url = SHOPIFY_DISCOUNT;
        const options = {
          method: 'GET',
          url: url + `?draft_order_id=${draftOrderId}`,
          body: null,
        };
        const res = await fetchClient(options);

        if (
          res.data.length == 0 ||
          res.data.find((e: any) => e.raw_data.title === DiscountEnum.BXGYDiffProduct) ===
            undefined
        ) {
          props.sentBxGyDiffProductToCart(true);
        } else {
          props.sentBxGyDiffProductToCart(
            res.data.filter((e: any) => e.raw_data.title === DiscountEnum.BXGYDiffProduct),
          );
        }

        if (res.data.find((e: any) => e.raw_data.title.includes('Buy Over')) === undefined) {
          props.sentFreeProductToCart(true);
        } else {
          props.sentFreeProductToCart(false);
        }
        const discountItem = res.data.filter((item: any) => {
          const hasEnoughProductVariants =
            item.raw_data?.customerGets?.items?.productVariants?.edges?.length > 1;
          const hasEnoughProducts =
            item.raw_data?.customerGets?.items?.products?.edges?.length > 1;
          return hasEnoughProductVariants || hasEnoughProducts;
        });
        const discount = [
          discountItem.reduce((highest: any, current: any) => {
            const highestAmout = Number(highest.raw_data?.customerBuys?.value?.amount);
            const currentAmout = Number(current.raw_data?.customerBuys?.value?.amount);
            return currentAmout > highestAmout ? current : highest;
          }),
        ];

        const promises = discount.map((discount: any) => {
          const optionsChild = {
            method: 'POST',
            url: url,
            body: {
              draft_order_id: draftOrderId,
              shopify_discount_code_id: discount.id,
              list_product_free: 'True',
            },
          };
          return fetchClient(optionsChild);
        });

        const results = await Promise.all(promises);
        const newDiscountedProducts: DiscountedProducts[] = results.map((result, index) => {
          const response = result as {
            data: {
              product_free: Product[];
              shopify_discount_code: string;
              quantityReceived: number;
            };
          };

          const quantity =
            discount[index].raw_data?.customerGets?.value?.quantity?.quantity || 0;
          const amount = discount[index].raw_data?.customerBuys?.value?.amount || '0';
          return {
            discountCode: response.data?.shopify_discount_code || '',
            products: response.data?.product_free || [],
            quantityReceived: quantity,
            discountTitle: discount[0].title,
            discountAmount: amount,
            discountDescription: discount[0].type,
          };
        });
        setDiscountedProducts(newDiscountedProducts);
        props.sentDiscountedProductToCart(newDiscountedProducts[0]);
        const bxgy = res.data.filter((item: any) => {
          const hasProducts =
            item.raw_data?.customerBuys?.items?.products?.edges?.length === 1 &&
            item.raw_data?.customerGets?.items?.products?.edges?.length === 1 &&
            item.raw_data?.customerBuys?.items?.products?.edges[0]?.node.id !==
              item.raw_data?.customerGets?.items?.products?.edges[0]?.node.id;

          const hasProductVariants =
            item.raw_data?.customerBuys?.items?.productVariants?.edges?.length > 0 &&
            item.raw_data?.customerGets?.items?.productVariants?.edges?.length > 0 &&
            item.raw_data?.customerBuys?.items?.productVariants?.edges[0]?.node.id !==
              item.raw_data?.customerGets?.items?.productVariants?.edges[0]?.node.id;

          const isDiscountAutomatic = item.raw_data?.__typename === 'DiscountAutomaticBxgy';

          return isDiscountAutomatic && (hasProducts || hasProductVariants);
        });
        const promisesBxgy = bxgy.map((discount: any) => {
          const optionsChild = {
            method: 'POST',
            url: url,
            body: {
              draft_order_id: draftOrderId,
              shopify_discount_code_id: discount.id,
              list_product_free: 'True',
            },
          };
          return fetchClient(optionsChild);
        });

        const resultsBxgy: any = await Promise.all(promisesBxgy);

        if (resultsBxgy && resultsBxgy[0]?.data?.product_free) {
          if (resultsBxgy[0]?.data?.product_free?.length > 0) {
            let checkBxGy = true;
            for (const product of order.line_items) {
              if (
                String(product.variant_id) ===
                String(resultsBxgy[0]?.data?.product_free[0].variant_id)
              ) {
                checkBxGy = false;
              }
            }
            if (checkBxGy) {
              const quantity = bxgy[0].raw_data?.customerGets?.value?.quantity?.quantity || 0;
              const amount = bxgy[0].raw_data?.customerBuys?.value?.amount || '0';
              const discountBxgy = {
                discountCode: bxgy[0].id || '',
                products: [],
                quantityReceived: quantity,
                discountTitle: bxgy[0].title,
                discountAmount: amount,
                discountDescription: bxgy[0].type,
              };
              const product: any = resultsBxgy[0]?.data?.product_free[0];
              product.quantity = 1;
              handleSubmit(product, discountBxgy);
            }
          }
        }
      } catch (error) {
        console.error('Error fetching discounts:', error);
      }
    };
    fetchDiscounts();
  }, [order, draftOrderId]);
  useEffect(() => {
    const lineItems: any = [];
    const selectedProducts: { [discountCode: string]: Product[] } = {};

    order?.line_items?.forEach((item: any) => {
      lineItems.push({
        variant_id: item?.variant_id,
        quantity: item?.quantity,
        price: item?.price,
        applied_discount: item?.applied_discount,
      });

      if (item.applied_discount) {
        const discountGroup = discountedProducts.find(
          (group) => group.discountTitle === item.applied_discount.title,
        );

        if (discountGroup) {
          const product = discountGroup.products.find((prod) => prod.id == item.product_id);

          if (product) {
            if (!selectedProducts[discountGroup.discountCode]) {
              selectedProducts[discountGroup.discountCode] = [];
            }
            selectedProducts[discountGroup.discountCode].push(product);
          }
        }
      }
    });

    setItemsQuantity(lineItems);
    setSelectedProductsByGroup(selectedProducts);
  }, [order, discountedProducts]);

  const handleProductClick = (product: Product, discountGroup: DiscountedProducts) => {
    const discountCode = discountGroup.discountCode;
    const isSelected =
      selectedProductsByGroup[discountCode]?.some((p) => p.id === product.id) || false;
    const data = {
      variant_id: product?.variant_id ?? '',
      quantity: product?.quantity ?? '',
    };
    if (isSelected) {
      setItemsQuantity((prev: any) => {
        const newState = prev
          .map((item: any) => {
            if (item.variant_id == data.variant_id) {
              if (item.quantity > 1) {
                return { ...item, quantity: item.quantity - 1, applied_discount: null };
              }
              return null;
            }
            return item;
          })
          .filter((item: any) => item !== null);
        clearTimeout(ref.current);
        ref.current = setTimeout(() => {
          updateCart(newState);
        }, 500);
        return newState;
      });
      setSelectedProductsByGroup((prev) => {
        const updatedGroup = prev[discountCode]?.filter((p) => p.id !== product.id) || [];
        return { ...prev, [discountCode]: updatedGroup };
      });
      toastrSuccess(`Free product has been removed to your cart`);
    } else {
      if (
        (selectedProductsByGroup[discountCode]?.length || 0) >= discountGroup.quantityReceived
      ) {
        toastrError(`You can only select up to ${discountGroup.quantityReceived} products.`);
      } else {
        setSelectedProductsByGroup((prev) => {
          const updatedGroup = [...(prev[discountCode] || []), product];
          return { ...prev, [discountCode]: updatedGroup };
        });
        setError(null);
        product.quantity = 1;
        handleSubmit(product, discountGroup);
      }
    }
  };

  const isProductSelectedInAnyGroup = (productId: number): boolean => {
    return Object.values(selectedProductsByGroup).some((products) =>
      products.some((p) => p.id === productId),
    );
  };

  const isProductSelectedInGroup = (productId: number, discountCode: string): boolean => {
    return selectedProductsByGroup[discountCode]?.some((p) => p.id === productId);
  };

  const handleSubmit = async (product: Product, discountGroup: DiscountedProducts) => {
    try {
      const url = SHOPIFY_DISCOUNT;
      const options = {
        method: 'POST',
        url: url,
        body: {
          shopify_discount_code: discountGroup.discountCode,
          draft_order_id: draftOrderId,
          product_choice: [product],
          cart_list: 'True',
          discountTitle: discountGroup.discountTitle,
          discountDescription: discountGroup.discountDescription,
        },
      };
      const res = await fetchClient(options);
      if (res.statusCode && res.statusCode !== 200) {
        toastrError(res.error || 'An error occurred. Please try again.');
      } else {
        toastrSuccess(`Free product has been added to your cart`);
        fetchCart();
      }
    } catch (error) {
      console.error('Error fetching discounts:', error);
    }
  };

  return (
    <div className="selectFreeProduct">
      {discountedProducts.length > 0 && (
        <div style={{ display: 'flex', flexWrap: 'wrap', margin: '0 auto' }}>
          {error && (
            <div style={{ color: 'red', width: '100%', textAlign: 'center' }}>{error}</div>
          )}
          {discountedProducts.map((group) => (
            <div key={group.discountCode} style={{ marginBottom: '20px' }}>
              <h5>
                Pick {group.quantityReceived} free gift(s) for purchase over ৳
                {group.discountAmount}
              </h5>
              <div className="groupFreeProduct" style={{ display: 'flex', flexWrap: 'wrap' }}>
                {group.products.map((product) => (
                  <div
                    className="freeProductBox"
                    key={`${product.id}-${group.discountCode}`}
                    onClick={() => handleProductClick(product, group)}
                    style={{
                      width: '47%',
                      border: '1px solid black',
                      margin: '5px 5px',
                      borderRadius: '5px',
                      borderWidth: isProductSelectedInGroup(product.id, group.discountCode)
                        ? '2px'
                        : '1px',
                      cursor:
                        !isProductSelectedInGroup(product.id, group.discountCode) &&
                        isProductSelectedInAnyGroup(product.id)
                          ? 'not-allowed'
                          : 'pointer',
                      opacity:
                        !isProductSelectedInGroup(product.id, group.discountCode) &&
                        isProductSelectedInAnyGroup(product.id)
                          ? 0.5
                          : 1,
                    }}
                  >
                    <div className="freeProduct">
                      <div className="freeProduct-image">
                        <Picture data={product?.image?.url} />
                      </div>
                      <div className="freeProduct-info">
                        <div className="freeProduct-name">{product.title}</div>
                        <div className="freeProduct-brand">{product.vendor}</div>
                        <div className="freeProduct-variant">
                          {product.variant_title !== 'Default Title'
                            ? product.variant_title
                            : ''}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SelectFreeProduct;
