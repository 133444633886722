import { useEffect } from 'react';
import { FOOTER_ID, BLOCK_ICON_ID } from '../../config';
import { isMobile } from '../../DetectScreen';

function useFixedBlockIcon() {
  const handleScroll = () => {
    const icons = document.getElementById(BLOCK_ICON_ID);
    const footer = document.getElementById(FOOTER_ID);
    if (icons) {
      const offset = !isMobile
        ? window.pageYOffset + icons.clientHeight + 194
        : window.pageYOffset + icons.clientHeight + 194 + 186;
      const height = icons.getBoundingClientRect().top + icons.clientHeight;
      if (offset >= height) {
      } else {
        icons.classList.remove('stick-icon');
        icons.classList.add('on-footer');
      }

      if (elementInViewport(footer)) {
        if (icons.classList.contains('stick-icon')) {
          icons.classList.remove('stick-icon');
        }
      }
      if (!elementInViewport(footer)) {
        if (!icons.classList.contains('stick-icon')) {
          if (offset >= height) {
            icons.classList.add('stick-icon');
          }
        }
      }
    }
  };

  const elementInViewport = (el: any) => {
    let top = el.offsetTop;
    let left = el.offsetLeft;
    const width = el.offsetWidth;
    const height = el.offsetHeight;

    while (el.offsetParent) {
      el = el.offsetParent;
      top += el.offsetTop;
      left += el.offsetLeft;
    }

    return (
      top < window.pageYOffset + window.innerHeight &&
      left < window.pageXOffset + window.innerWidth &&
      top + height > window.pageYOffset &&
      left + width > window.pageXOffset
    );
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);
}

export default useFixedBlockIcon;
