import React from 'react';
import phoneIcon from '../../assets/images/phone.svg';
import whatsappIcon from '../../assets/images/whatsapp.svg';
import { PHONE_CONTACT, WHATSAPP_NUMBER } from '../../config';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { CUSTOMER_SERVICE_HOURS, CUSTOMER_SERVICE_NUMBER } from '../../config';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

const Contact = () => {
  return (
    <div className="contact">
      <div className="contact-item">
        <span className="text-red">Need help?</span>
      </div>
      <HtmlTooltip
        title={
          <React.Fragment>
            <Typography color="inherit">Call Customer Service for Assistance</Typography>
            <br></br>
            <p>{'Our Customer Experience team is available at'}</p>
            <br></br>
            <p>
              <b>{CUSTOMER_SERVICE_NUMBER}</b>
            </p>
            <br></br>
            <p>{CUSTOMER_SERVICE_HOURS}</p>
          </React.Fragment>
        }
      >
        <div className="contact-item">
          <div className="image">
            <img src={phoneIcon} alt="phone" />
          </div>

          <a href={`tel:${PHONE_CONTACT}`} className="text">
            CALL US
          </a>
        </div>
      </HtmlTooltip>
      <HtmlTooltip
        title={
          <React.Fragment>
            <Typography color="inherit">
              Message us on <b>{CUSTOMER_SERVICE_NUMBER}</b>
            </Typography>
            <br></br>
            <p>
              {'One of our Customer Experience Representatives will get back to you shortly'}
            </p>
            <br></br>
            <p>{'Our Customer Experience Team is available during'}</p>
            <br></br>
            <p>{CUSTOMER_SERVICE_HOURS}</p>
          </React.Fragment>
        }
      >
        <div className="contact-item">
          <div className="image">
            <img src={whatsappIcon} alt="WHATSAPP" />
          </div>
          <a href={`https://wa.me/${WHATSAPP_NUMBER}`} target="__blank" className="text">
            WHATSAPP
          </a>
        </div>
      </HtmlTooltip>
    </div>
  );
};

export default Contact;
