import { combineReducers } from 'redux';
import homeReducer from './home/reducer';
import productReducer from './product/reducer';
import userReducer from './auth/reducer';
import globalConfigReducer from './globalConfig/reducer';
import siteSettingsReducer from './siteSettings/reducer';
import checkoutV2Reducer from './checkoutV2/reducer';

const rootReducer = combineReducers({
  userReducer,
  homeReducer,
  productReducer,
  globalConfigReducer,
  siteSettingsReducer,
  checkoutV2Reducer,
});
export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
