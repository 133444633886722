import React, { memo } from 'react';
import Picture from '../Picture';
import { map } from 'lodash';
import { useHistory } from 'react-router-dom';
import { getDiscountedPrice, moneyFormater } from '../../redux/Helpers';
import { PERFUME_TYPE_TAGS, PERFUME_TYPE_MAP } from '../../config';
import { directDetailProduct, filterProducts } from '../../utils/helpers/functions';

function ProductSlide(props: any) {
  const history = useHistory();

  const redirectDetailProduct = (handle: any, brandName: any) => {
    directDetailProduct(history, handle, brandName);

    props.closeSubNav ? props.closeSubNav() : props.handleCloseShoppingBag();
  };

  const getPerfumeType = (tags: string[]): string => {
    for (let i = 0; i < tags?.length; i++) {
      if (PERFUME_TYPE_TAGS.includes(tags[i].toLowerCase()))
        return PERFUME_TYPE_MAP[tags[i].toLowerCase()];
    }
    return '';
  };

  const willShowDiscount = (item: any): boolean => {
    const show =
      item?.max_discount &&
      item?.max_discount?.value &&
      item?.max_discount?.show_on_detail_page &&
      item?.max_discount?.show_on_homepage;
    return show;
  };

  const countNumberOfDiscount = (item: any) => {
    const discounts: any = [];
    item?.product_variants?.forEach((e: any) => {
      if (!discounts?.includes(e?.discount)) {
        discounts?.push(e?.discount);
      }
    });
    return discounts?.length;
  };

  const getLowestPriceVariantHasDiscount = (variants: any) => {
    const sort = variants?.sort(function (a: any, b: any) {
      return (parseFloat(a.price) || 0) - (parseFloat(b.price) || 0);
    });

    const lowestProductPriceHasDiscount: any = sort?.find((x: any) => x?.discount > 0);
    if (lowestProductPriceHasDiscount) {
      return lowestProductPriceHasDiscount;
    } else {
      return sort?.[0];
    }
  };

  return (
    <section className="blk-product-slide">
      <div className="x-mandatory">
        {map(filterProducts(props.data), (item, index) => {
          const itemKey = item.value || item;
          const imageKey = itemKey.images ? itemKey.images[0]?.url : itemKey?.image?.url;
          const lowestPriceVariantHasDiscount = getLowestPriceVariantHasDiscount(
            itemKey?.product_variants,
          );

          return (
            <div className={`col-${props.col}`} key={index}>
              <div className="wrapper">
                {willShowDiscount(itemKey) && (
                  <span
                    className="sale-inf"
                    style={{
                      visibility: itemKey?.max_discount?.show_on_detail_page
                        ? 'visible'
                        : 'hidden',
                    }}
                  >
                    {countNumberOfDiscount(itemKey) > 1 && 'Up to '}-
                    {itemKey?.max_discount?.value}%
                  </span>
                )}

                <div
                  onClick={() =>
                    !props.isGifItem
                      ? redirectDetailProduct(
                          !props.closeSubNav ? itemKey.handle : itemKey.handle,
                          itemKey?.brand_page?.page_ptr?.handle,
                        )
                      : ''
                  }
                >
                  <div className="section-square">
                    <Picture data={imageKey ? imageKey : ''} />
                    {item.sale ? <span className="sale-inf">-50%</span> : ''}
                  </div>
                  <div className="item-info">
                    <div>
                      <h4>{itemKey?.brand?.name}</h4>
                      <p>{itemKey?.name}</p>
                      {getPerfumeType(itemKey?.tags) ? (
                        <p className="perfume-type">{getPerfumeType(itemKey?.tags)}</p>
                      ) : null}
                    </div>
                    <div>
                      <div className={itemKey.sale ? 'sale-price' : ''}>
                        {itemKey?.product_variants?.length > 1 && 'From'}
                        &nbsp;
                        {itemKey?.product_variants && lowestPriceVariantHasDiscount?.price ? (
                          <span
                            className={`price ${
                              willShowDiscount(itemKey) &&
                              lowestPriceVariantHasDiscount?.discount > 0
                                ? 'strike-through'
                                : ''
                            }`}
                          >
                            {`${moneyFormater(lowestPriceVariantHasDiscount?.price)}`}
                          </span>
                        ) : null}
                      </div>
                      {willShowDiscount(itemKey) &&
                      lowestPriceVariantHasDiscount?.discount > 0 ? (
                        <span className="discount-price">
                          {getDiscountedPrice(
                            lowestPriceVariantHasDiscount?.price,
                            lowestPriceVariantHasDiscount?.discount,
                          )}
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
}

export default memo(ProductSlide);
