import * as actions from './actions';

export interface state {
  logo: any;
  nav: any;
  footer: any;
  basicSettings: any;
}
const defaultState: state = {
  logo: null,
  nav: null,
  footer: null,
  basicSettings: null,
};

export default function globalConfigReducer(
  state: state = defaultState,
  action: actions.setGlobalConfigActionInterface,
): state {
  switch (action.type) {
    case actions.SET_GLOBAL_CONFIG:
      return {
        ...state,
        ...action?.payload,
      };

    default:
      return state;
  }
}
