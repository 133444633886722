import React, { useState, useEffect } from 'react';
import FloatingWhatsApp from 'react-floating-whatsapp';
import SundoraLogo from '../../images/sundora-logo.jpg';
import { useLocation } from 'react-router';
import { PHONE_WHATSAPP } from '../../config';

const Whatsapp = () => {
  const location = useLocation();
  const [active, setActive] = useState(true);

  useEffect(() => {
    if (location?.pathname?.startsWith('/receipt/')) {
      setActive(false);
    } else {
      setActive(true);
    }
  }, [location]);

  return (
    <>
      {active && (
        <div className="whatsapp">
          <FloatingWhatsApp
            phoneNumber={PHONE_WHATSAPP}
            accountName="Sundora"
            className="whatsapp-button"
            avatar={SundoraLogo}
            chatMessage={'Greetings from Sundora Beauty.\nHow may we help you?'}
            statusMessage={'Experience True Beauty'}
            allowClickAway
            allowEsc
          />
        </div>
      )}
    </>
  );
};

export default Whatsapp;
