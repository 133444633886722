import { lazy } from 'react';
import IRoute from '../interfaces/route';
import { PREFIX_URL_HOME, PREFIX_URL_KIDS, PREFIX_URL_SPORTS } from '../config';

const routes: IRoute[] = [
  {
    path: '/',
    name: 'Home Page',
    component: lazy(() => import('../containers/HomePage')),
    exact: true,
  },
  {
    path: `/${PREFIX_URL_KIDS}`,
    name: 'Kids Home page',
    component: lazy(() => import('../containers/HomePage')),
    exact: true,
  },
  {
    path: `/${PREFIX_URL_HOME}`,
    name: 'Home Home page',
    component: lazy(() => import('../containers/HomePage')),
    exact: true,
  },
  {
    path: `/${PREFIX_URL_SPORTS}`,
    name: 'Sports Home page',
    component: lazy(() => import('../containers/HomePage')),
    exact: true,
  },
  {
    path: '/blog',
    name: 'Blogs Page',
    component: lazy(() => import('../containers/BlogListPage')),
    exact: true,
  },
  {
    path: '/blog',
    name: 'Article Page',
    component: lazy(() => import('../containers/ArticlePage')),
    exact: false,
  },
  {
    path: '/blogs',
    name: 'Blogs Page',
    component: lazy(() => import('../containers/BlogListPage')),
    exact: true,
  },
  {
    path: '/all-product/',
    name: 'Product Page',
    component: lazy(() => import('../containers/BrandProductPage')),
    exact: true,
  },
  {
    path: `/${PREFIX_URL_KIDS}/all-product/`,
    name: 'Product Page',
    component: lazy(() => import('../containers/BrandProductPage')),
    exact: true,
  },
  {
    path: `/${PREFIX_URL_HOME}/all-product/`,
    name: 'Product Page',
    component: lazy(() => import('../containers/BrandProductPage')),
    exact: true,
  },
  {
    path: `/${PREFIX_URL_SPORTS}/all-product/`,
    name: 'Product Page',
    component: lazy(() => import('../containers/BrandProductPage')),
    exact: true,
  },
  {
    path: '/brand/:IDBrand/:IDProduct',
    name: 'Product Detail Page',
    component: lazy(() => import('../containers/ProductPage')),
    exact: true,
  },
  {
    path: `/${PREFIX_URL_KIDS}/brand/:IDBrand/:IDProduct`,
    name: 'Product Detail Page',
    component: lazy(() => import('../containers/ProductPage')),
    exact: true,
  },
  {
    path: `/${PREFIX_URL_HOME}/brand/:IDBrand/:IDProduct`,
    name: 'Product Detail Page',
    component: lazy(() => import('../containers/ProductPage')),
    exact: true,
  },
  {
    path: `/${PREFIX_URL_SPORTS}/brand/:IDBrand/:IDProduct`,
    name: 'Product Detail Page',
    component: lazy(() => import('../containers/ProductPage')),
    exact: true,
  },
  {
    path: '/checkout',
    name: 'Checkout Page',
    component: lazy(() => import('../containers/CheckoutV2Page')),
    exact: false,
  },
  {
    path: '/page/:idDefaultPage',
    name: 'Page',
    component: lazy(() => import('../containers/PageDefault')),
    exact: false,
  },
  {
    path: '/product/',
    name: 'Search result Page',
    component: lazy(() => import('../containers/SearchResultPage')),
    exact: false,
  },
  {
    path: `/${PREFIX_URL_KIDS}/product/`,
    name: 'Search result Page',
    component: lazy(() => import('../containers/SearchResultPage')),
    exact: false,
  },
  {
    path: `/${PREFIX_URL_HOME}/product/`,
    name: 'Search result Page',
    component: lazy(() => import('../containers/SearchResultPage')),
    exact: false,
  },
  {
    path: `/${PREFIX_URL_SPORTS}/product/`,
    name: 'Search result Page',
    component: lazy(() => import('../containers/SearchResultPage')),
    exact: false,
  },
  {
    path: '/forgot-password/',
    name: 'Forgot password Page',
    component: lazy(() => import('../containers/ForgotPasswordPage')),
    exact: true,
  },
  {
    path: '/forgot-password/:id',
    name: 'Forgot password Page',
    component: lazy(() => import('../containers/VerifyForgotPasswordPage')),
    exact: true,
  },
  {
    path: '/verify-email/:id',
    name: 'Verify customer email Page',
    component: lazy(() => import('../containers/VerifyCustomerEmailPage')),
    exact: true,
  },
  {
    path: '/404',
    name: 'Not found Page',
    component: lazy(() => import('../components/Notfound')),
    exact: true,
  },
  {
    path: `/${PREFIX_URL_KIDS}/404`,
    name: 'Not found Page',
    component: lazy(() => import('../components/KidsNotfound')),
    exact: true,
  },
  {
    path: `/${PREFIX_URL_HOME}/404`,
    name: 'Not found Page',
    component: lazy(() => import('../components/KidsNotfound')),
    exact: true,
  },
  {
    path: `/${PREFIX_URL_SPORTS}/404`,
    name: 'Not found Page',
    component: lazy(() => import('../components/KidsNotfound')),
    exact: true,
  },
  {
    path: '/receipt/:id',
    name: 'Receipt page',
    component: lazy(() => import('../containers/ReceiptPage')),
    exact: true,
  },
  {
    path: '/payment-success',
    name: 'Payment success page',
    component: lazy(() => import('../containers/PaymentSuccessPage')),
    exact: true,
  },
  {
    path: '/store/register',
    name: 'Register',
    component: lazy(() => import('../containers/RegisterPage')),
    exact: true,
  },
  {
    path: '/passwordless-login/:token/:uid/:hash',
    name: 'Passwordless login',
    component: lazy(() => import('../containers/PasswordlessLoginPage')),
    exact: true,
  },
];

export default routes;
