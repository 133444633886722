import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { PREFIX_URL, PREFIX_URL_DEFAULT } from '../../config';

const RedirectPrefix = () => {
  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    const segment: any = location.pathname.split('/')[1];
    if (!PREFIX_URL?.includes(segment)) {
      history.push(location.pathname + location.search);
    }
  }, []);

  return '';
};

const ReducePrefix = () => {
  useEffect(() => {
    const splitURL = window.location.pathname.split('/');
    const departments: any = [];
    for (let i = 0; i < splitURL.length - 1; i++) {
      if (PREFIX_URL.includes(splitURL[i])) {
        departments.push(splitURL[i]);
      }
    }
    if (departments.length > 1) {
      departments.pop();
      const filteredURL = splitURL.filter((segment) => !departments.includes(segment));
      const url = filteredURL.join('/');
      if (url.charAt(url.length - 1) === '/') {
        url.slice(0, -1);
      }
      window.location.pathname = url;
    }
  });
  return '';
};

export const prefix: any = PREFIX_URL?.includes(window.location.pathname.split('/')[1])
  ? window.location.pathname.split('/')[1]
  : PREFIX_URL_DEFAULT;

const usePrefixSegment = () => {
  return [prefix, RedirectPrefix, ReducePrefix];
};

export default usePrefixSegment;
